@import "../../../../../../../assets/styles/variables";

.language_select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  height: 49px;
  overflow: hidden;
  transition: 0.2s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &.expanded {
    border-radius: 7px 7px 0 0;
    box-shadow: none;
    overflow: auto;
    height: 100%;
    transition: 0.2s ease;
  }

  .item {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    color: $font-main-color;
    padding: 11.5px 15px;
    width: 100%;
    border-bottom: 1px solid $border-color-main;
    background: $color-white;
    transition: 0.2s ease;
    cursor: pointer;

    &:hover {
      background: #f2fcff;
      transition: 0.2s ease;
    }

    &.active {
      color: $font-main-color;
      background: $color-white;
    }

    &.selected {
      background: $color-purple-active;
      color: $color-white;
    }

    .icon {
      path {
        fill: $color-purple-active;
      }

      &.active {
        path {
          fill: $color-purple-active;
        }
      }
    }

    .arrow {
      transform: rotate(0);
      transition: 0.4s ease;

      &.rotated {
        transform: rotate(180deg);
        transition: 0.4s ease;
      }

      path {
        fill: $color-purple-active;
      }

      &.active {
        path {
          fill: $color-purple-active;
        }
      }
    }

    span {
      font-size: inherit;
      font-weight: inherit;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &.title {
      padding: 14px 15px;
      min-height: 50px;

      span {
        margin: 0 auto 0 10px;
        font-weight: 700;
      }
    }

    &:last-child {
      &.item {
        border-radius: 0 0 7px 7px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  ul {
    padding-bottom: 5px
  }
}
