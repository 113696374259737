.match_selector {
  background: inherit;
  width: 60px;
  height: 100%;
  padding-bottom: 120px;
  transition: 0.4s linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden !important;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &.expanded {
    width: 350px;
    transition: 0.4s linear;
  }
}
