.adminPanelWrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (min-width: 1921px) {
    max-width: 100vw;
  }
}
