@import "../../../../../../../../assets/styles/variables";

.animation {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: flex-end;
  height: 50px;
  overflow: hidden;
  transition: 0.4s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &.expanded {
    overflow: auto;
    height: 204px;
    transition: 0.4s ease;
  }

  &__title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px 7px 0px 0px;
    border-bottom: 1px solid $border-color-main;
    background: $color-white;
    min-height: 50px;
    padding: 0 20px 0 20px;
    cursor: pointer;

    h2 {
      font-weight: 700;
      font-size: 16px;
      color: $font-main-color;
      margin: 0 auto 0 0;
      padding: 0 0 0 20px;
    }

    input {
      display: none;
    }

    label {
      position: relative;
      display: inline-flex;
      cursor: pointer;
    }

    .arrow {
      transform: rotate(180deg);
      transition: 0.4s ease;

      path {
        fill: $font-secondary-color;
      }

      &.rotated {
        transform: rotate(0);
        transition: 0.4s ease;
      }
    }
  }

  .animation__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 7px 7px;
    padding: 10px 60px 15px 60px;
    background: $color-white;

    &_title {
      font-family: inherit;
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: $font-secondary-color;
      margin: 0 0 12px 0;
    }

    &_upload {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 12px 0;

      input {
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        color: $font-secondary-color;
        border-radius: 7px;
        padding: 8px 5px;
        border: 1px solid $font-secondary-color !important;
        background: $color-white;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: text;

        &::placeholder {
          font-size: inherit;
          color: inherit;
          opacity: 1;
          transition: 0.4s ease;
        }

        &:focus {
          &::placeholder {
            opacity: 0;
            transition: 0.4s ease;
          }
        }
      }
    }

    &_description {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-family: inherit;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $font-secondary-color;
      width: 100%;
      max-width: 242px;
      word-break: break-word;
      text-align: left;
    }
  }
}
