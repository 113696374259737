.palette {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 auto 0 0;
    box-sizing: border-box;

    &:last-of-type {
      margin: 0;
    }

    .list_title {
      font-family: inherit;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin: 0 0 5px 0;
      color: inherit;
    }

    .list_item_wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      list-style-type: none;

      li {
        position: relative;
        margin: 0 8px 0 0;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 50%;
        border: none;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

        &:last-of-type {
          margin: 0;
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }

    input + .list_item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      font-size: 0;
      border-radius: inherit;
      cursor: pointer;
    }
  }
}
