@import "../../../../../assets/styles/variables";

.options__section {
  background: inherit;
  width: 60px;
  height: 100%;
  padding-bottom: 140px;
  transition: width 0.4s linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &.expanded {
    width: 450px;
    transition: width 0.4s linear;
    z-index: 2;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $color-white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  min-height: 60px;
  width: 100%;
  margin: 0 0 10px 0;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 60px;
  }
}

.mainSettingsWrapper {
  padding-bottom: 5px;
}

.iconStyle {
  path {
    fill: $font-secondary-color;
  }

  &:hover {
    path {
      fill: $color-purple-active;
      transition: 0.2s ease;
    }
  }
}

.iconStyleActive, .iconLanguageActive, .iconSettingsActive {
  background: $color-purple-active;

  path {
    fill: $color-white;
  }
}

.iconSettingsActive {
  border-radius: 7px 0 0 7px;
}

.iconLanguageActive {
  border-radius: 0 7px 7px 0;
}
