@import "../../../../../../../../../../assets/styles/variables";

.button_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: $color-white;
  width: 100%;
  min-height: 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 7px 7px;

  button {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font-weight: 400;
    font-size: 14px;
    color: $color-white;
    border-radius: 30px;
    background: $color-blue;
    padding: 0 5px;
    width: 100%;
    max-width: 143px;
    min-height: 30px;
    cursor: pointer;

    span {
      margin: 0 0 0 6px;
      color: inherit;
    }

    svg {
      path {
        fill: $color-white;
      }
    }
  }
}
