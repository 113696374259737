@import "../../../../../../../../assets/styles/variables.scss";

.popup {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 319px;
  min-height: 319px;
  border: 5px solid $popup-main-color;
  background: $color-grey;
  box-sizing: border-box;
  z-index: 100;
  transition: 2s ease;

  &::before {
    content: "";
    position: absolute;
    top: calc(0% + 15px);
    left: calc(0% + 15px);
    width: 100%;
    height: 100%;
    border: 5px dashed $popup-main-color;
    background: transparent;
    z-index: -1;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 52px 19px 0 35px;
  }

  &__title {
    font-family: inherit;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    word-break: break-word;
    white-space: pre-line;
    color: $popup-main-color;
    text-align: center;
    margin: 0 0 27px 0;
  }

  &__btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 167.5px;
    min-height: 44px;
    background: $color-blue;
    border-radius: 29px;
    word-break: break-word;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    color: $color-white;
    line-height: normal;
    cursor: pointer;
  }
}
