@import "../../../../../../../../assets/styles/variables";

.timeline {
  width: 100%;
  margin-bottom: 5px;

  &__title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px;
    box-shadow: 0 2px 3px rgb(0, 0, 0, 0.1);
    background: $color-white;
    min-height: 50px;
    padding: 0 20px 0 20px;

    h2 {
      font-weight: 700;
      font-size: 16px;
      color: $font-main-color;
      margin: 0 auto 0 0;
      padding: 0 0 0 20px;
      text-transform: capitalize;
    }

    input {
      display: none;
    }

    label {
      position: relative;
      display: inline-flex;
      cursor: pointer;
    }
  }
}
