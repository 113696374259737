@import "../../../../assets/styles/variables.scss";

.select_wrapper {
  position: relative;
  border-radius: 7px;
  list-style-type: none;
  margin: 0 0 10px 0;

  .select {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background: $color-white;
    border-radius: 7px;
    border: 1px solid $font-secondary-color;
    width: 100%;
    min-height: 40px;
    padding: 0 16px 0 13px;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    color: $font-main-color;
    box-sizing: border-box;
    z-index: 1;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 66px;
      height: 2px;
      top: -1px;
      background: $color-white;
    }

    span {
      margin: 0 auto 0 0;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .label {
      position: absolute;
      top: calc(0% - 8px);
      left: calc(0% + 11px);
      min-width: 68px;
      font-family: inherit;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: $border-color-secondary;
      pointer-events: none;
      z-index: 2;
    }

    .arrow {
      position: relative;
      pointer-events: none;
      transform: rotate(0deg);
      transition: transform 0.2s ease;

      &.active {
        transition: transform 0.2s ease;
        transform: rotate(180deg);
      }

      path {
        fill: $font-main-color;
      }
    }
  }

  .select__list {
    position: fixed;
    top: calc(0% + 201px);
    left: calc(0% + 20px);
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 350px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    height: 100%;
    max-height: 657px;
    z-index: 3;
    overflow: auto;

    &.active {
      opacity: 1;
      pointer-events: initial;
      height: auto;
      transition: 0.2s ease;
      box-shadow: none;
    }

    .option {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      font-family: inherit;
      font-weight: 400;
      font-size: 16px;
      color: $font-main-color;
      padding: 12px 15px;
      width: 100%;
      border-bottom: 1px solid $border-color-main;
      background: $color-white;
      transition: 0.2s ease;
      cursor: pointer;

      &:hover {
        background: #f2fcff;
        transition: 0.2s ease;
      }

      &.selected {
        background: $color-purple-active;
        color: $color-white;

        &:hover {
          background: $color-purple-active;
          transition: 0.2s ease;
        }
      }

      span {
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
