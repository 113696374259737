.options {
  position: fixed;
  right: calc(0% + 20px);
  top: calc(0% + 91px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 2;

  section {
    position: absolute;
    top: calc(0% + 65px);
    right: 0;
    bottom: 0;

    &:first-of-type {
      top: 0;
      bottom: 0;
    }

    &:last-of-type {
      bottom: 0;
    }
  }
}
