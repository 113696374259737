@import "../../../../../assets/styles/variables.scss";
$button-left-width: 350px;
$button-right-width: 300px;

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 73px;
  box-sizing: border-box;
  padding: 0 25px;
  background: $color-purple;
  z-index: 1;

  &__btn_state_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: $color-purple-active;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 29px;
    max-width: $button-left-width;
    width: 100%;
    min-height: 50px;
    padding: 0 3px 0 3px;
  }

  &__animation_resolution_wrapper {
    width: 100%;
    padding-inline-start: 10px;
    padding-inline-end: calc(10px + #{$button-left-width} - #{$button-right-width});
  }

  &__button_right_wrapper {
    max-width: $button-right-width;
    width: 100%;
    padding-inline-start: 32px;
  }

  &__state_btn {
    flex: 1;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $color-white;
    min-height: 44px;
    border-radius: 30px;
    word-break: break-word;
    background: transparent;
    cursor: pointer;

    &.active {
      background: $color-blue;
      font-weight: 700;
      max-width: 176.5px;
      width: 100%;
    }
  }

  &__input {
    display: none;
  }

  &__btn {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    max-width: 120px;
    width: 100%;
    margin: 0 16px 0 0;
    padding: 5px 10px;
    cursor: pointer;

    label,
    a, span {
      display: inline-block;
      font-size: 14px;
      line-height: 15px;
      font-weight: 400;
      margin: 0 0 0 7.5px;
      color: $color-white;
      cursor: pointer;
    }

    &:last-of-type {
      margin: 0;
    }

    input {
      position: center;
      z-index: -1;
    }
  }
}
