@import "../../../../../../../../../../assets/styles/variables";

.statistics__types {
  width: 100%;
  padding: 10px 45px 14px 60px;
  background: $color-white;

  &_title {
    font-family: inherit;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: $font-secondary-color;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    list-style-type: none;

    .list_item {
      display: flex;
      justify-content: flex-start;
      width: 65px;
      height: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin: 0 auto 0 0;

      &:first-of-type {
        input:checked + label {
          color: $color-purple-active;

          &::before {
            top: 5px;
          }
        }

        label {
          width: 100%;
          padding-inline-start: 25px;

          &::before {
            top: 5px;
          }
        }
      }

      &:last-of-type {
        margin: 0;
      }

      input {
        display: none;
      }

      label {
        width: 100%;
        padding-inline-start: 25px;
        padding-top: 5px;
        position: relative;
        cursor: pointer;

        .icon {
          pointer-events: none;

          svg {

          }

          path {
            fill: $font-secondary-color;
          }

          &.active {
            path {
              fill: $color-purple-active;
            }
          }
        }
      }

      input:checked + label {
        color: $color-purple-active;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          box-sizing: border-box;
          border: 1.5px solid $color-purple-active;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          text-align: center;
          transform: translateY(50%);
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color-purple-active;
          transform: translate(50%, 150%);
        }
      }

      input + label {
        position: relative;
        color: $font-secondary-color;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          border: 1.5px solid $font-secondary-color;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          transform: translateY(50%);

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            width: 10px;
            height: 10px;
            background-color: $color-purple-active;
            transform: translate(50%, -50%);
          }
        }
      }
    }

    .list_item_disabled {
      opacity: 0.5;
    }
  }
}
