@import "./assets/styles/reset";
@import "./assets/styles/variables";
@import "./assets/styles/custom_scrollbar.scss";

html {
  font-family: $font-stack;
  font-size: 14px;
  overflow: auto;
  background: $color-grey;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 1;
  background: $color-grey;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

text {
  font-family: inherit;
}

.container {
  position: relative;
  width: 100%;
  padding: 0 20px 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.app {
  flex: 1;
  height: 100vh;
  background: $color-grey;
}

.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 18px;
}
