.preloader {
  position: relative;
  width: 100%;
  background: transparent;
  text-align: center;

  img {
    width: 50px;
    max-width: 100%;
    height: auto;
  }
}
