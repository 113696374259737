body {
  .os-host {
    width: 100%;
    height: 100%;
    max-height: inherit;
    font-family: inherit;

    .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
      background: rgba(0, 0, 0, 0.4);
    }

    .os-scrollbar.os-scrollbar-vertical {
      .os-scrollbar-track.os-scrollbar-track {
        background: $border-color-main;
        border-radius: 5px;
        width: 3px;
      }
    }

    .os-scrollbar {
      padding: 0;

      &.os-scrollbar-vertical {
        width: 3px;
        top: 1px;
        right: 0;
        height: calc(100% - 2px);

        .os-scrollbar-track {
          &.os-scrollbar-track {
            .os-scrollbar-handle {
              max-height: 26px;
              width: 3px;
              min-height: 0;
              left: 0;
              top: 0;
              border-radius: 5px;
              background: $font-secondary-color;
            }
          }
        }
      }
    }
  }
}
