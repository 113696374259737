@import "../../../../../../../assets/styles/variables.scss";

.sportsType__event_list {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  transition: 0.2s ease;
  overflow: auto;

  &.active {
    opacity: 1;
    height: auto;
    transition: 0.2s ease;
  }
}

.sportsType__event {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  font-family: inherit;
  height: 45px;
  overflow: hidden;

  &_title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    background: $color-white;
    border-radius: 7px;
    box-sizing: border-box;
    min-height: 40px;
    font-weight: 400;
    font-size: 16px;
    color: $font-main-color;
    padding: 0 16px 0 28px;
    margin: 0 0 5px 0;
    width: 100%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #F2FCFF;
    }

    span {
      display: inline-flex;
      max-width: 80%;
      color: inherit;
    }

    b {
      display: inline-flex;
      font-weight: 400;
      margin: 0 21px 0 0;
    }

    .arrow {
      transition: 0.2s ease;

      path {
        fill: $font-main-color;
        transition: 0.2s ease;
      }
    }

    &.active_title {
      border-left: 8px solid $color-purple-active;
      padding: 0 16px 0 20px;
      color: $color-purple-active;

      b {
        color: $color-purple-active;
      }

      .arrow {
        transform: rotate(180deg) !important;
        transition: 0.2s ease;

        path {
          fill: $color-purple-active;
          transition: 0.2s ease;
        }
      }
    }
  }

  &_match {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-height: 60px;
    background: $color-white;
    color: #565D6A;
    border-radius: 7px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    padding: 0 16px 0 28px;
    max-width: 322px;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.2s;

    &.active_match {
      border-left: 8px solid $color-purple-active;
      padding-inline-start: 20px;
      color: $color-purple-active;
    }

    &:hover {
      background-color: #F2FCFF;
    }

    .title {
      display: inline-flex;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: inherit;
      max-width: 135px;
      margin: 0 auto 0 0;
      word-break: break-word;
    }

    .start_date {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: inherit;

      .time {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  &.active {
    height: auto;
    overflow: auto;

    .sportsType__event_title {
      .arrow {
        transition: 0.2s ease;
        transform: rotate(0deg);
      }
    }
  }
}
