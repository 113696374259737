$color-white: #fff;
$color-blue: #00bffe;
$color-purple: #6359e6;
$color-purple-active: #7166f9;
$color-purple-light: #bfbaff;
$color-grey: #f5f5fa;

$font-stack: "Roboto", sans-serif;
$font-main: "Roboto";
$font-main-color: #565d6a;
$font-secondary-color: #848b97;

$border-color-main: #e1e1e1;
$border-color-secondary: #b7b7b7;

$popup-main-color: #8076ff;
