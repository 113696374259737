@import "../../../../../../../../../../assets/styles/variables";

.header__types {
  position: relative;
  width: 100%;
  padding: 10px 60px 20px 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background: $color-white;
  border-radius: 0 0 7px 7px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: inherit;
  }

  &_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0 0 0;
    list-style-type: none;

    .list_item {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 20px 0;

      &:last-of-type {
        margin: 0;
      }

      input {
        display: none;
      }

      label {
        position: relative;
        display: inline-flex;
        cursor: pointer;
      }

      span {
        display: inline-block;
        font-size: inherit;
        line-height: inherit;
        color: $font-main-color;
        padding: 0 0 0 10px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
