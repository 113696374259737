@import "../../../../../../../assets/styles/variables.scss";

.range_slider_container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 18px 30px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 29px;
  background: $color-purple-active;

  span {
    font-size: 14px;
    font-weight: 400;
    color: $color-white;
  }

  .range_slider {
    position: relative;
    margin: 0 14px 0 14px;
    width: 60px;
    flex: 1;
  }

  input {
    appearance: none;
    width: 100%;
    height: 4px;
    outline: none;
    border-radius: 19px;
    background: #bfbaff;
    cursor: pointer;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: $color-white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25), 0 0 1px 7px rgba(255, 255, 255, 0.33);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      appearance: none;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: $color-white;
      box-shadow: 0 0 1px 7px rgba(255, 255, 255, 0.33);

      cursor: pointer;
    }

    &::-ms-thumb {
      appearance: none;
      width: 22px;
      height: 22px;
      border: none;
      border-radius: 50%;
      background: $color-white;
      box-shadow: 0 0 1px 7px rgba(255, 255, 255, 0.33);
      cursor: pointer;
    }
  }
}

label[for="range"] {
  width: auto;
  height: auto;
  position: absolute;
  top: calc(0% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: $color-white;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  svg {
    height: inherit;
    position: relative;
    left: 0;
    z-index: -1;
    pointer-events: none;
    fill: $color-blue;

    path {
      fill: $color-blue;
    }
  }
}
