@import "../../../../../../../../assets/styles/variables";

.statistics {
  width: 100%;
  height: 50px;
  overflow: hidden;
  transition: 0.4s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &.expanded {
    overflow: auto;
    transition: 0.4s ease;
  }

  &__title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid $border-color-main;
    background: $color-white;
    color: $font-main-color;
    min-height: 50px;
    padding: 0 20px 0 20px;
    cursor: pointer;

    h2 {
      font-weight: 700;
      font-size: 16px;
      margin: 0 auto 0 20px;
      padding: 0 0 0 20px;
    }

    input {
      display: none;
    }

    label {
      position: relative;
      display: inline-flex;
      cursor: pointer;
    }

    .arrow {
      transform: rotate(180deg);
      transition: 0.4s ease;

      path {
        fill: $font-secondary-color;
      }

      &.rotated {
        transform: rotate(0);
        transition: 0.4s ease;
      }
    }
  }
}
