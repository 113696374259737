@import "../../../../../../../assets/styles/variables";

$defaultThemeHeight: 351px;
$multipleThemesHeight: 472px;
$bottomPadding: 5px;

.themes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 7px;
  background: $color-white;
  max-height: calc(100% - $bottomPadding);
  height: $defaultThemeHeight;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &__title {
    position: relative;
    font-family: inherit;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: $font-main-color;
    background: $color-white;
    border-radius: 7px 7px 0 0;
    padding: 12.5px 20px;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: $border-color-main;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.multipleThemes {
  height: $multipleThemesHeight;
}
