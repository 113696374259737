@import "../../../../../../../../../../assets/styles/variables";

.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 658px;
  min-height: 365px;
  border-radius: 7px;
  background: $color-white;
  color: $color-white;
  z-index: 100;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: inherit;
    border-radius: 7px;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 67px;
    width: 100%;
    padding: 0 20px;
    border-radius: 7px 7px 0 0;
    background: $color-purple-active;

    .title {
      font-family: inherit;
      font-weight: 700;
      font-size: 18px;
    }

    svg {
      cursor: pointer;
      fill: $color-white;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background: inherit;
    padding: 30px 39px 0 20px;
    border-bottom: 1px solid $border-color-secondary;

    input {
      width: 100%;
      background: inherit;
      border-radius: 7px;
      font-weight: 400;
      font-size: 16px;
      color: $font-main-color;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid $border-color-secondary;
      padding: 10px 13px;
      cursor: pointer;

      &::placeholder {
        color: inherit;
        opacity: 1;
        transition: 0.2s ease;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
          transition: 0.2s ease;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    min-height: 80px;
    background: inherit;
    border-radius: 0 0 7px 7px;
    padding: 0 15px;

    button {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 16px;
      color: $font-main-color;
      border-radius: 30px;
      border: 1px solid $font-main-color;
      background: transparent;
      width: 100%;
      max-width: 149px;
      min-height: 44px;
      box-sizing: border-box;
      cursor: pointer;

      &.confirm {
        margin: 0 0 0 19px;
        color: $color-white;
        background: $color-purple-active;
        border: 1px solid $color-purple-active;
      }
    }
  }

  &__backdrop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    background: $font-main-color;
    width: 100vw;
    height: 100vh;
    opacity: 0.6;
  }
}

.validation_message {
  color: red;
  opacity: 0.6;
  padding: 10px 15px;
}
