@import "../../../../assets/styles/variables.scss";

.section_caption {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background: $color-white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  min-height: 60px;
  width: 100%;
  padding: 18px;
  margin: 0 0 10px 0;
  cursor: pointer;

  &.left_sided {
    .arrow {
      transform: rotate(180deg);
    }
  }

  &.right_sided {
    flex-direction: row-reverse;

    .arrow {
      transform: none;
    }
  }

  .arrow {
    display: block;
    height: 0;
    width: 0;
    opacity: 0;

    path {
      fill: $color-purple-active;
    }
  }

  h2 {
    display: inline-flex;
    font-family: inherit;
    line-height: 24px;
    font-size: 0;
    height: 0;
    width: 0;
    opacity: 0;
    color: $color-purple-active;
  }

  &.fullWidth {
    transition: 0.5s linear;
    justify-content: space-between;

    h2 {
      font-size: 16px;
      font-weight: 700;
      height: auto;
      width: auto;
      opacity: 1;
      transition: 0.5s ease;
    }

    .arrow {
      height: auto;
      width: auto;
      opacity: 1;
      transition: 0.5s ease;
    }
  }

  .icon {
    transition: 0.2s ease;

    &:hover {
      path {
        fill: $color-purple-active;
        transition: 0.2s ease;
      }
    }

    path {
      fill: $font-secondary-color;
    }

    &.active {
      path {
        fill: $color-purple-active;
      }
    }
  }

  .iconLanguage {
    transition: 0.2s ease;

    &:hover {
      path {
        fill: $color-purple-active;
        transition: 0.2s ease;
      }
    }

    path {
      fill: $font-secondary-color;
    }

    &.active {
      path {
        fill: $color-purple-active;
      }
    }
  }
}
