.demo_mode_widget {
  position: fixed;
  left: calc(0% + 20px);
  top: calc(0% + 91px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 2;

  section {
    margin: 0 0 5px 0;

    &:last-of-type {
      margin: 0;
    }
  }
}
