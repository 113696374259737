@import "../../../../../../../assets/styles/variables.scss";

.events_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 340px;
  max-width: 100%;
  padding-bottom: 5px;

  &__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
    color: $font-main-color;
    background: $color-white;
    border-radius: 7px;
    padding: 0 28px 0 28px;
    margin: 0 0 5px 0;
    box-sizing: border-box;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-height: 40px;
    cursor: pointer;

    span {
      max-width: 80%;
      margin: 0 auto 0 0;
    }

    .play_btn {
      display: none;

      path {
        fill: $color-purple-active;
      }
    }

    &:last-of-type {
      margin: 0;
    }

    &.active {
      border-left: 8px solid $color-purple-active;
      color: $color-purple-active;
      padding: 0 17px 0 20px;

      .play_btn {
        display: block;
      }
    }
  }
}
