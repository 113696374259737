@import "../../../../../../../../../assets/styles/variables";

.theme {
  position: relative;
  width: 100%;
  background: $color-white;
  padding: 10px 21px 15px 42px;
  border-bottom: 1px solid $border-color-main;
  color: $font-secondary-color;
  cursor: pointer;

  &__title {
    height: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 3px 0;
    color: $font-secondary-color;

    &.active {
      color: $color-purple-active;
    }

    h2 {
      font-family: inherit;
      font-weight: 700;
      font-size: 14px;
      color: inherit;
      text-align: left;
      margin: 0 11px 0 0;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    span {
      cursor: pointer;

      svg {
        pointer-events: none;

        path {
          fill: $font-secondary-color;
        }
      }

      &.active {
        svg {
          path {
            fill: $color-purple-active;
          }
        }
      }
    }
  }

  &__id {
    font-size: 10px;
    margin-bottom: 7px;
  }

  &__active_selector {
    position: absolute;
    left: calc(0% + 17px);
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__delete_icon {
    margin-left: auto;
    cursor: pointer;

    svg {
      pointer-events: none;
      path {
        fill: $font-secondary-color;
      }
    }
  }
}

.notActiveSelector {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #e1e1e1;
  position: absolute;
  left: calc(0% + 17px);
  top: 50%;
  transform: translate(-50%, -50%);
}
